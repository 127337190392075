<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { DishHistoryResponse } from '@/types/api.js'
import Button from 'primevue/button'
import { computed, nextTick, onMounted, ref, watch, type Ref } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { Dish } from '@/types/dish.js'
import type { HistoryState } from '@/types/history.js'
import { useToast } from 'primevue/usetoast'
import { useProfileDishLists } from '@/composables/useProfileDishLists.js'
import HistoryCard from '@/components/Profile/HistoryCard.vue'
import { usePlanningStore } from '@/stores/planningStore'
import TitleSuggestionButton from '@/components/Menus/TitleSuggestionButton.vue'
import AddDishTitleToPlanningDialog from '@/components/Planning/AddDishTitleToPlanningDialog.vue'
import { useConfirm } from 'primevue/useconfirm'
import { useAuth } from '@/composables/useAuth'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const toast = useToast()
const { user } = useAuth()
const dishesStore = useDishesStore();


const { fetchData } = useFetch()

const apiUrl = useEnvStore().apiUrl

const searchQuery = ref('')
const isSearching = ref(false)

const createdUsing: Ref<string[]> = ref(['ocr', 'ia'])

const isFetchingList = ref(false)

const nextPageUrl = ref(`${apiUrl}/dishes`)
const loadedDishes = ref(0)
const totalDishes = ref(0)
const planningStore = usePlanningStore()
const selectedDishTitle: Ref<Dish | null> = ref(null);
const showAddToPlanningDialog: Ref<boolean> = ref(false)
const confirm = useConfirm()


const { dishTitles, handleDuplication, handleDeletion, handleFavoriteChange } =
  useProfileDishLists(toast)

const groupedDishesByDate = computed(() => {
  const groups: { [key: string]: Dish[] } = {}
  dishTitles.value.forEach((dish) => {
    const date = new Date(dish.created_at).toLocaleDateString('fr-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(dish)
  })
  return Object.entries(groups)
})

function handleCardClick(dish: Dish) {
  selectedDishTitle.value = dish;
  planDishTitle();
  // if (planningStore.choosingDishToPlan) {
  //   emit('addDishToPlan', dishes.value.find((dish) => dish.id === id) as Dish)
  //   return
  // } else {
  //   saveState()
  //   router.push(`/menu/dishes/${id}`)
  // }
}
function planDishTitle() {
  if (!selectedDishTitle.value) return
  planningStore.dishTitleToPlan = {
    clipboardDishTitle: selectedDishTitle.value.name,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true

}

// When the users wants to add a dish from the planning.
const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
}>()

async function getDishes() {
  // This var is needed because the isFetching var from composable
  // Makes a conflict between the getDishes and the searchDishes methods
  isFetchingList.value = true

  const { data, error } = await fetchData(nextPageUrl.value, 'GET')
  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    console.log('response', response)
    dishTitles.value = [...dishTitles.value, ...response.data]
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }

  isFetchingList.value = false
}

// This method is only triggered when we type in the serach bar or when the checkboxes are clicked.
// The pagination is the handled by getDishes
async function filterDishes() {
  // isSearching is needed to display search icon when the method is triggered with the watcher
  isSearching.value = true

  // const searchUrl = `${apiUrl}/dishes/search?name=${encodeURIComponent(searchQuery.value)}`
  console.log("FETCHING ")
  const searchUrl = `${apiUrl}/dishes/titles/search?${new URLSearchParams({
    name: searchQuery.value,
    created_using: createdUsing.value.join(','),
    // is_planned: 'true',
  })}`

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    dishTitles.value = response.data
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }
  isSearching.value = false
}

const scrollPosition = ref(0)

function saveState() {
  const stateToSave: HistoryState = {
    dishes: dishTitles.value,
    loadedDishes: loadedDishes.value,
    totalDishes: totalDishes.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
    searchQuery: searchQuery.value,
    createdUsing: createdUsing.value,
  }
  sessionStorage.setItem('titleHistoryState', JSON.stringify(stateToSave))
}

function restoreState() {
  dishTitles.value = []
  const savedState = sessionStorage.getItem('titleHistoryState')
  if (savedState) {
    const state: HistoryState = JSON.parse(savedState)
    dishTitles.value = state.dishes
    loadedDishes.value = state.loadedDishes
    totalDishes.value = state.totalDishes
    nextPageUrl.value = state.nextPageUrl
    scrollPosition.value = state.scrollPosition
    searchQuery.value = state.searchQuery
    createdUsing.value = state.createdUsing || [] // Provide default empty array if not found
    console.log(scrollPosition.value)
    nextTick(() => {
      window.scrollTo(0, scrollPosition.value)
    })
    sessionStorage.removeItem('titleHistoryState')
  } else {
    filterDishes()
  }
}

watch(searchQuery, () => {
  if (searchQuery.value === '') {
    filterDishes()
  }
})

const confirmGenerateNewDish = (event, dish: Dish, index, sourceArray) => {
  const dishTitle = dish.name;
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('menuDishView.generateDishFromTitle.popupLabelGenerateOrPlan', { dishTitle }),
    icon: 'pi pi-question-circle',
    acceptLabel: t('menuDishView.generateDishFromTitle.planDishTitle'),
    rejectLabel: t('menuDishView.generateDishFromTitle.confirm'),
    accept: () => {
      // generateDishFromTitle(dishTitle, index, sourceArray)
      handleCardClick(dish)
      toast.add({
        severity: 'info',
        summary: t('common.loading'),
        detail: t('menuDishView.loadingMenuLabel'),
        life: 3000,
      })
    },
    reject: () => {
      selectedDishTitle.value = dish;
      generateDishFromTitle();
    },
  })
}

async function generateDishFromTitle() {
  if (!user.value) return;
  const restaurantId = user.value.restaurants[0].id
  if (!selectedDishTitle.value) {
    console.error('Title is required to generate a dish');
    return;
  }
  router.push({ path: `/menu/dishes/${selectedDishTitle.value.id}` })
}

onMounted(() => {
  restoreState()
})
</script>

<template>
  <main>
    <div class="flex flex-col gap-2 mb-6">
      <AutoComplete v-model="searchQuery" :suggestions="dishTitles" :loading="isSearching" @complete="filterDishes"
        panel-class="hidden" :placeholder="t('common.searchPlaceHolder')" />
      <!-- <div class="flex flex-row gap-6">
        <div class="flex flex-row items-center gap-2">
          <Checkbox v-model="createdUsing" inputId="ia" name="ia" value="ia" @change="filterDishes" />
          <label for="ia">{{ t('profile.dishLists.iaGenerated') }}</label>
        </div>
        <div class="flex flex-row items-center gap-2">
          <Checkbox v-model="createdUsing" inputId="ocr" name="ocr" value="ocr" @change="filterDishes" />
          <label for="ocr">{{ t('profile.dishLists.ocrGenerated') }}</label>
        </div>
      </div> -->
    </div>
    <!-- <HistoryCard v-for="dish in dishes" :key="dish.id" :dish="dish" @card-clicked="handleCardClick(dish.id)"
      @handle-deletion="(wasDeleted: boolean) => handleDeletion(wasDeleted, dish.id)" @handle-duplication="(duplicationResponse: null | Dish) => handleDuplication(duplicationResponse, dish.id)
        " @handle-favorite-change="(favoriteResponse: boolean | null) => handleFavoriteChange(favoriteResponse, dish.id, true)
          " /> -->
    <!-- <TitleSuggestionButton :title="dish.name" @click=" confirmGenerateNewDish($event, dish,
      index, 'otherDishTitles' )" v-for="(dish, index) in dishTitles" :key="index" /> -->
    <div v-for="([date, dishes], groupIndex) in groupedDishesByDate" :key="groupIndex" class="mb-8">
      <h3 class="text-lg font-bold mb-4">{{ date }}</h3>
      <TitleSuggestionButton v-for="(dish, index) in dishes" :key="dish.id" :title="dish.name"
        @click="confirmGenerateNewDish($event, dish, index, 'otherDishTitles')" />
    </div>
    <Button v-if="loadedDishes < totalDishes" @click="getDishes()" :loading="isFetchingList && dishTitles.length !== 0"
      label="Voir plus" />
    <LoadingView v-if="isFetchingList && dishTitles.length === 0" />
    <AddDishTitleToPlanningDialog v-model:visible="showAddToPlanningDialog"
      @close-sidebar="showAddToPlanningDialog = false" />
  </main>
</template>
